import { Box, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  generalContainer: {
    minHeight: theme.maxHeightPageContainer,
    backgroundColor: theme.customColor.background.secondary,
    borderRadius: theme.spacing(0.5),
    padding: `0px ${theme.spacing(2)}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ReDefaultNoPermissionPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box className={classes.generalContainer}>
      <Typography variant="h4">{t('noPermissionTitle')}</Typography>
    </Box>
  );
};

export default ReDefaultNoPermissionPage;
