import { getLSSession } from 'localstorage/getter';
import { getDiffTime, updateSession } from './functions';

/* eslint-disable no-useless-escape */
export const phoneNumber = (value) => {
  return value.length > 6;
};

export const pin = (value) => {
  return value.length === 4 && /^\d+$/.test(value);
};

export const validatePositiveNumber = (value, isPlusAllowed) => {
  // regex hanya positive number
  // const regex = /^[0-9]\d*$/;

  // regex positive number + decimal
  if (isPlusAllowed) {
    return /^[+]$/.test(value) || /^[+]?(\d+(?:\.\d*)?|\.\d+)$/.test(value);
  }
  const regex = /^(\d+(?:\.\d*)?|\.\d+)$/;
  return regex.test(value);
};

export const isDecimal = (value) => {
  const regex = /^[-+]?\d+\.\d+$/;
  return regex.test(value);
};

export const checkEmailFormat = (value) => {
  if (value) {
    const regexEmail =
      /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    return regexEmail.test(value);
  }
  return true;
};

export const checkPasswordFormat = (value) => {
  if (value) {
    const regexPassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return regexPassword.test(value);
  }
  return true;
};

// ======== CALCULATE ASPECT RATIO
// https://stackoverflow.com/questions/1186414/whats-the-algorithm-to-calculate-aspect-ratio
export const gcd = (width, height) => {
  if (height === 0) {
    return width;
  }
  return gcd(height, width % height);
};

export const checkAspectRatio = (
  image,
  targetRatio,
  checkResolution,
  callback,
) => {
  if (image) {
    let width = 0;
    let height = 0;
    const img = new Image();
    img.src = window.URL.createObjectURL(image);
    img.onload = () => {
      width = img.width;
      height = img.height;

      const aspectWidth = +width / gcd(+width, +height);
      const aspectHeight = +height / gcd(+width, +height);

      let isValid =
        aspectWidth === targetRatio[0] && aspectHeight === targetRatio[1];

      // checkResolution
      if (checkResolution) {
        isValid = isValid && width >= 1000;
      }
      callback({ result: isValid, image });
    };
  }
  return null;
};

export const checkMaxResolution = (
  image,
  targetWidth,
  targetHeight,
  callback,
) => {
  if (image) {
    let width = 0;
    let height = 0;
    const img = new Image();
    img.src = window.URL.createObjectURL(image);
    img.onload = () => {
      width = img.width;
      height = img.height;

      // checkResolution
      const isValid =
        width <= (targetWidth || 1000) && height <= (targetHeight || 1000);
      callback({ result: isValid, image });
    };
  }
  return null;
};

// ======== CALCULATE ASPECT RATIO

export const msrEndWithQuestionMark = (value) => {
  const regex = /.*\?(\\n)*\"$/;
  const localValue = JSON.stringify(value);
  return regex.test(localValue);
};

export const checkSession = () => {
  // Session akan berubah setiap kali ada aktivitas
  // dan akan expired ketika 24 jam tidak ada aktivitas

  // 24 jam (diubah ke menit) variable dibuat terpisah biar maintainnya gampang
  const maxHour = 24;
  const exactMinutes = maxHour * 60;

  const sessionTime = getLSSession();
  if (!sessionTime) {
    // buat session jika di LS belum ada
    updateSession();
  } else {
    const diffTime = getDiffTime(sessionTime, 'minutes');
    // lebih dari 24 jam kick
    if (diffTime > exactMinutes) {
      return false;
    }
    // jika session masih valid, lakukan update timestamp sessionnya
    updateSession();
  }
  return true;
};
