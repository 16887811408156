export const _APP_IDS = {
  development: 'realm-login-trigger-ajvak',
  staging: 'realm-login-trigger-ppzbe',
  beta: 'realm-login-trigger-wwyxj',
  production: 'realm-login-trigger-ahawv',
};

export const getAppId = () => {
  let envi = import.meta.env.MODE.toLowerCase();
  if (import.meta.env.MODE.toLowerCase().includes('localdev')) {
    envi = 'development';
  } else if (import.meta.env.MODE.toLowerCase().includes('localtest')) {
    envi = 'staging';
  }
  let appid = _APP_IDS[envi] || _APP_IDS.development;
  return appid;
};
