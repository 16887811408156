export const LSname = {
  credential: "creds",
  i18n: "i18nextLng",
  businessCategory: "business_category",
  business: "business",
  region: "region",
  outlets: "outlets",
  permission: "permission",
  session: "log_session",
  stockAudit: "stock_audit",
  stockAdjust: "stock_adjust",
  devlog: "devlog",
  be_version: "be_versions",
};
