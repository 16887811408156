import React, { useEffect, useContext } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import { useDrawerContext } from 'context/DrawerContext';
import { useContentContext } from 'context/ContentContext';
import { useTranslation } from 'react-i18next';

import * as Mongo from 'mongoservices/setup';
import { callWebhook, realmWebhook } from 'mongoservices/webhook';

import PageContainer from 'pages/back-office/admin-panel/PageContainer';
import ReDefaultNoPermissionPage from 'components/ReDefaultNoPermissionPage';
import { DrawerMenuData } from 'assets/file/DrawerMenuList';
import {
  clearAuthCredSession,
  setAuthSession,
  setCredentialSession,
} from 'sessionstorage/setter';
import {
  getAuthSession,
  getCredentialSession,
  getSessionBusiness,
} from 'sessionstorage/getter';
import {
  setLSBusinessCategory,
  setLSBusinesses,
  setLSCredential,
  setLSOutlets,
  setLSRegion,
} from 'localstorage/setter';
import {
  getLSBusinessCategory,
  getLSBusinesses,
  getLSCredential,
  getLSOutlets,
  getLSRegion,
} from 'localstorage/getter';
import { checkSession } from 'utils/validation';
import { logoutBackOffice } from 'utils/helper';
import PropTypes from 'prop-types';

/* ===========> BACA DULU SEBELUM EDIT <===========

TOLONG JANGAN MASUKKAN RANDOM VARIABLE KE SINI, ATAU KONSULTASIKAN KE MAINTAINER BO

===========> BACA DULU SEBELUM EDIT <=========== */

if (performance.getEntriesByType('navigation')[0].type === 'reload') {
  // karena pakai sessionStorage, yang mana storage akan terclear pada saat close Tab
  // maka diperlukan pengecekan isReload, untuk mendeteksi f5 atau refresh
  // ketika refresh mayoritas state yang sudah ada akan hilang,
  // jadi perlu di inititate ulang datanya
  clearAuthCredSession();
}

export const AuthContext = React.createContext([{}, () => {}]);
const getBusiness = async () => {
  const businessData = await Mongo.Call(
    Mongo.realmFunction.clientUserBusiness,
    'GET',
    null,
    { hide_empty_outlet: false },
  );
  setLSBusinesses(businessData);
  // DISPATCH AGAR RESIMPLETOPBAR BISA MELISTEN PERUBAHAN STORAGE
  window.dispatchEvent(new StorageEvent('storage', { key: 'business' }));
  return businessData;
};

const getBusinessCategory = async () => {
  const businessCategoryData = await callWebhook(
    realmWebhook.businessCategory,
    null,
    'GET',
  );
  setLSBusinessCategory(businessCategoryData);
  return businessCategoryData || [];
};

const getCountries = async () => {
  const countries = await callWebhook(
    realmWebhook.clientGetRegion,
    null,
    'GET',
  );

  setLSRegion(countries);
  return countries || [];
};

const getOutlets = async () => {
  const outletData = await Mongo.Call(
    Mongo.realmFunction.clientOutlets,
    'LIST',
    null,
  );
  setLSOutlets(outletData);
  // DISPATCH AGAR RESIMPLETOPBAR BISA MELISTEN PERUBAHAN STORAGE
  window.dispatchEvent(new StorageEvent('storage', { key: 'outlets' }));
  return outletData || [];
};

const checkBusinessData = async () => {
  let data = getLSBusinesses();
  if (!data || (Array.isArray(data) && data.length === 0)) {
    data = await getBusiness();
  }
  return data;
};

const checkBusinessCategoryData = async () => {
  const data = getLSBusinessCategory();
  if (!data || (Array.isArray(data) && data.length === 0)) {
    await getBusinessCategory();
  }
};

const checkCountryData = async () => {
  const data = getLSRegion();
  if (!data || (Array.isArray(data) && data.length === 0)) {
    await getCountries();
  }
};

const checkOutletData = async () => {
  const data = getLSOutlets();
  if (!data || (Array.isArray(data) && data.length === 0)) {
    await getOutlets();
  }
};

const AuthProvider = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { setDrawerState, setTitle, setSelectedBusiness } = useDrawerContext();
  const { setLoading } = useContentContext();

  const getCurrentUserData = async () => {
    // ini digunakan untuk menyimpan current user (id, fullname) dan embed di LS creds
    // untuk keperluan tau siapa sekarang yang login di semua halam BO
    const user = await Mongo.Call(Mongo.realmFunction.clientUser);
    let localCreds = getLSCredential();
    if (user?.id) {
      localCreds = {
        ...localCreds,
        id: user.id,
        fullname: user.fullname,
        email: user.email,
        username: user.username,
      };
    }
    setLSCredential(localCreds);
    setDrawerState({
      currentUser: user || {},
    });
  };

  const getInitiateData = async () => {
    setLoading({
      loading: true,
      topPosition: true,
      label: t('preparingData'),
      location: 'rootAuth',
    });

    getCurrentUserData();

    // dibawah ini bakal ngehit server ketika
    // 1. page di reload (f5/ctrl+r)
    // 2. page rerender karena save project (khusus development)
    // 3. browser/tab tutup dan dibuka lagi
    // tetapi ketika on rerender karena click menu / setstate dll, gak bakal ke trigger
    const businessData = await checkBusinessData();
    await checkBusinessCategoryData();
    await checkCountryData();
    await checkOutletData();

    if (!getSessionBusiness()) {
      setSelectedBusiness(!businessData?.error ? businessData[0].id : '');
    }

    setLoading({ loading: false, location: 'rootAuth' });
  };

  const isLoggedIn = (validCred) => {
    const isAuthenticated = getAuthSession();
    if (!validCred) {
      setDrawerState({ showDrawer: false });
      logoutBackOffice();
    } else if (validCred && !isAuthenticated) {
      setAuthSession(true);
      getInitiateData();
    }
  };

  const checkExpired = () => {
    const credential = getLSCredential();
    return credential?.expired;
  };

  const isNotByPassPages = () => {
    const bypassPages = [
      // Halaman profile
      props.menuKey === DrawerMenuData.profile.key,
      // Halaman Billing History
      props.subMenu === DrawerMenuData.setting.subMenu.billingHistory.key,
    ];

    return !bypassPages.includes(true);
  };

  useEffect(() => {
    const getCredentials = getLSCredential() || null;

    const validCredentials = getCredentials && getCredentials.hit_access;
    const credentialsState = getCredentialSession();

    if (credentialsState === null || credentialsState !== !!validCredentials) {
      setCredentialSession(!!validCredentials);
      isLoggedIn(!!validCredentials);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    const session = checkSession();
    const expired = checkExpired();

    // EXCEPTION JIKA EXPIRED DAN BUKAN TERMASUK PAGE YANG DI BYPASS,
    // MAKA REDIRECT KE SUBSCRIPTION
    if (expired && isNotByPassPages()) {
      navigate('/setting/subscription/detail-payment', {
        state: {
          type: 'extend',
          expired: location?.state?.expired,
        },
      });
    }

    if (session) {
      setTitle({
        data: {
          newTitle: t(props.title).toUpperCase(),
          menuKey: props.menuKey,
          subMenuKey: props.subMenu,
          filterParam: props.useFilter,
          outletFilter: props.outletFilter,
          useAllOutletFilter: props.allOutletFilter,
          filterButton: props.filterButton,
          bisnisOutletFilter: props.bisnisOutletFilter,
        },
      });
    } else {
      logoutBackOffice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.title, props.key, props.subMenu, location.pathname]);

  return (
    <AuthContext.Provider
      value={{
        getCountries,
        getBusiness,
        getBusinessCategory,
        getOutlets,
        getCurrentUserData,
      }}>
      <PageContainer noDrawer={props.noDrawer}>
        {!props.permitAccess || props.disabled === true ? (
          <ReDefaultNoPermissionPage />
        ) : (
          <>{React.cloneElement(props.children, {})}</>
        )}
      </PageContainer>
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const value = useContext(AuthContext);
  if (value == null) {
    throw new Error('useAuthContext() called outside of a PosProvider?');
  }
  return value;
};

AuthProvider.propTypes = {
  menuKey: PropTypes.string,
  subMenu: PropTypes.string,
  title: PropTypes.string,
  useFilter: PropTypes.bool,
  outletFilter: PropTypes.bool,
  allOutletFilter: PropTypes.bool,
  filterButton: PropTypes.bool,
  bisnisOutletFilter: PropTypes.bool,
  key: PropTypes.string,
  noDrawer: PropTypes.bool,
  permitAccess: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

export default AuthProvider;
