import { Icon } from '@material-ui/core';

import {
  Assessment,
  Storefront,
  LocalMall,
  Kitchen,
  Deck,
  Group,
  AccountBalanceWalletOutlined,
  Loyalty,
  ReceiptTwoTone,
} from '@material-ui/icons';
import { ACCESS_BO } from './AccessControl';

export const DrawerMenuData = {
  profile: {
    icon: <Icon>how_to_reg</Icon>,
    key: 'profile',
    permitAccess: true,
    subMenu: {},
  },
  // dashboard: { icon: <Dashboard />, key: 'dashboard', subMenu: {} },
  report: {
    icon: <Assessment />,
    key: 'report',
    permitAccess: ACCESS_BO.REPORT,
    subMenu: {
      sales: { key: 'sales', default: true },
    },
  },
  product: {
    icon: <LocalMall />,
    key: 'product',
    permitAccess: ACCESS_BO.PRODUCT,
    subMenu: {
      group: { key: 'group', default: true },
      department: { key: 'department' },
      productList: { key: 'product-list' },
      menuVariant: { key: 'menu-variant' },
      package: { key: 'package' },
      modifier: { key: 'modifier' },
      menuPage: { key: 'menu' },
    },
  },
  utility: {
    icon: <Icon>construction</Icon>,
    key: 'utility',
    permitAccess: ACCESS_BO.UTILITY,
    subMenu: {
      typeSales: { key: 'type-sales', default: true },
      taxes: { key: 'taxes' },
      discount: { key: 'discount' },
      paymentMedia: { key: 'payment-media' },
      billDesign: { key: 'bill-design' },
      voidMemo: { key: 'void-memo' },
      refundMemo: { key: 'refund-memo' },
    },
  },
  ewallet: {
    icon: <AccountBalanceWalletOutlined />,
    key: 'ewallet',
    permitAccess: ACCESS_BO.EWALLET,
    subMenu: {},
  },
  promo: {
    icon: <Loyalty />,
    key: 'promo',
    permitAccess: ACCESS_BO.PROMO,
    subMenu: {},
  },
  manageOutlet: {
    icon: <Storefront />,
    key: 'manage-outlet',
    permitAccess: ACCESS_BO.OUTLET,
    subMenu: {
      outletList: { key: 'outlet-list', default: true },
      device: { key: 'device' },
      configuration: { key: 'configuration' },
    },
  },
  table: {
    icon: <Deck />,
    key: 'manage-table',
    permitAccess: ACCESS_BO.TABLE,
    subMenu: {
      tableLayout: { key: 'table-layout', default: true },
    },
  },
  inventory: {
    icon: <Kitchen />,
    key: 'inventory',
    permitAccess: undefined,
    subMenu: {
      stockSummary: {
        key: 'stock-summary',
        permitAccess: [ACCESS_BO.STOCK_ADJUSTMENT, ACCESS_BO.STOCK_AUDIT],
        default: true,
      },
      stockAdjustment: {
        key: 'stock-adjustment',
        permitAccess: ACCESS_BO.STOCK_ADJUSTMENT,
      },
      stockAudit: { key: 'stock-audit', permitAccess: ACCESS_BO.STOCK_AUDIT },
    },
  },
  member: {
    icon: <Group />,
    key: 'member-list',
    permitAccess: ACCESS_BO.MEMBER,
    subMenu: {},
  },
  invoice: {
    icon: <ReceiptTwoTone />,
    key: 'invoice',
    permitAccess: ACCESS_BO.INVOICE,
    subMenu: {},
    disabled: true,
  },
  employee: {
    icon: <Icon>engineering</Icon>,
    key: 'employee',
    permitAccess: ACCESS_BO.STAFF,
    subMenu: {
      employeeList: { key: 'employee-list', default: true },
      role: { key: 'role' },
    },
  },
  setting: {
    icon: <Icon>admin_panel_settings</Icon>,
    key: 'setting',
    permitAccess: ACCESS_BO.ACCOUNT_SETTING,
    subMenu: {
      subscription: { key: 'subscription', default: true },
      billingHistory: { key: 'billing-history' },
      deviceLicense: { key: 'device-license' },
    },
  },
};

const DrawerMenuList = [];

Object.keys(DrawerMenuData).forEach((key) => {
  const menu = { ...DrawerMenuData[key] };
  const subMenu = [];
  Object.keys(menu.subMenu).forEach((subKey) => {
    subMenu.push(menu.subMenu[subKey]);
  });
  menu.subMenu = subMenu;
  DrawerMenuList.push(menu);
});

export default DrawerMenuList;
