export const CookieName = {
  SELECTED_BUSINESS: 'sBusiness',
  SELECTED_OUTLET: 'sOutlet',
};

export const getCookie = (value) => {
  const localCookie = decodeURIComponent(document.cookie);
  const splittedCookie = localCookie.split(';');
  const findValue = splittedCookie.findIndex((cookie) =>
    cookie.includes(value),
  );

  if (findValue >= 0) {
    const result = splittedCookie[findValue]?.split('=');
    return result[1];
  }
  return null;
};

export const setCookie = (value) => {
  document.cookie = value;
};
