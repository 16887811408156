import React, { useState, useContext, useRef, useEffect } from 'react';

import { withTranslation } from 'react-i18next';
import {
  postMessageKeys,
  senderPostMessageBO,
  senderPostMessageReport,
} from 'utils/postMessage';
import PropTypes from 'prop-types';
import { cloneData } from 'utils/functions';
import { getLSI18N } from 'localstorage/getter';

const salesPath = import.meta.env.VITE_REPORT_UI;

/* ===========> BACA DULU SEBELUM EDIT <===========

TOLONG JANGAN MASUKKAN RANDOM VARIABLE KE SINI, ATAU KONSULTASIKAN KE MAINTAINER BO

===========> BACA DULU SEBELUM EDIT <=========== */

export const IframeContext = React.createContext([{}, () => {}]);

const IframeProvider = ({ children }) => {
  const _refPanelBO = useRef();
  const _refGeneralReport = useRef();
  const [iframeBoReady, setIframeBoReady] = useState(false);
  const [iframeReportReady, setIframeReportReady] = useState(false);
  const [state, setState] = useState({
    boUrl: '',
    creds: {},
    report_access: '',
    report_appId: '',
  });

  const setContextState = (newData) => {
    setState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const getContextState = (key) => {
    if (key) {
      return state[key];
    }
    return state;
  };

  const setBOUrl = (url) => {
    setContextState({ boUrl: url });
  };

  const loginReportCredential = (report_access, report_appId) => {
    if (report_access && report_appId) {
      setContextState({ report_access, report_appId });
    }
  };

  const sendPMCredential = (creds) => {
    if (creds) {
      setContextState({ creds });
    }
  };

  useEffect(() => {
    if (getContextState().creds?.hit_access && iframeBoReady) {
      const dataToSend = cloneData(getContextState().creds);
      dataToSend.lang = getLSI18N();

      senderPostMessageBO(
        _refPanelBO.current,
        postMessageKeys.sendPMCredential,
        dataToSend,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getContextState().creds, iframeBoReady]);

  useEffect(() => {
    if (
      getContextState().report_access &&
      getContextState().report_appId &&
      iframeReportReady
    ) {
      senderPostMessageReport(
        _refGeneralReport.current,
        postMessageKeys.loginReport,
        {
          report_access: getContextState().report_access,
          report_appId: getContextState().report_appId,
          lang: getLSI18N(),
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    iframeReportReady,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getContextState().report_access,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getContextState().report_appId,
  ]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === 'bo-ready') {
        setIframeBoReady(true);
      } else if (event.data === 'report-ready') {
        setIframeReportReady(true);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <IframeContext.Provider
      value={{
        getContextState,
        setContextState,
        sendPMCredential,
        setBOUrl,
        loginReportCredential,
      }}>
      {children}
      {getContextState().boUrl && (
        <iframe
          ref={_refPanelBO}
          src={`${getContextState().boUrl}/admin-panel`}
          title="GENERAL IFRAME PANEL BO"
          style={{ display: 'none' }}
          frameBorder="none"
        />
      )}
      {getContextState().report_access && getContextState().report_appId && (
        <iframe
          ref={_refGeneralReport}
          src={`${salesPath}`}
          title="GENERAL IFRAME REPORT"
          style={{ display: 'none' }}
          frameBorder="none"
        />
      )}
    </IframeContext.Provider>
  );
};

export const useIframeContext = () => {
  const value = useContext(IframeContext);
  if (value == null) {
    throw new Error('useIframeContext() called outside of a PosProvider?');
  }
  return value;
};

IframeProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default withTranslation()(IframeProvider);
