import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

const ReSnackBar = ({ show, type, message, onClose }) => (
  <Snackbar
    open={show}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    autoHideDuration={3000}
    onClose={(event, trigger) => {
      if (trigger !== 'clickaway') {
        onClose();
      }
    }}
    key={`${type}${message}`}>
    <Alert
      severity={type}
      variant="filled"
      style={{
        minWidth: 300,
      }}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={onClose}
          style={{ marginLeft: 50 }}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }>
      {message}
    </Alert>
  </Snackbar>
);

ReSnackBar.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
};

export default ReSnackBar;
