import { LSname } from 'localstorage';

export const setLocalStorage = (key, value, raw) => {
  if (raw) localStorage.setItem(key, value);
  else localStorage.setItem(key, JSON.stringify(value));
};

// SETTER
export const setLSPermission = (value) => {
  setLocalStorage(LSname.permission, value);
};

export const setLSCredential = (value) => {
  setLocalStorage(LSname.credential, value);
};

export const setLSSession = (value) => {
  setLocalStorage(LSname.session, value, true);
};

export const setLSDevlog = (value) => {
  setLocalStorage(LSname.devlog, value);
};

export const setLSStockAdjust = (value) => {
  setLocalStorage(LSname.stockAdjust, value);
};

export const setLSStockAudit = (value) => {
  setLocalStorage(LSname.stockAudit, value);
};

export const setLSRegion = (value) => {
  setLocalStorage(LSname.region, value);
};

export const setLSBusinessCategory = (value) => {
  setLocalStorage(LSname.businessCategory, value);
};

export const setLSBusinesses = (value) => {
  setLocalStorage(LSname.business, value);
};

export const setLSOutlets = (value) => {
  setLocalStorage(LSname.outlets, value);
};

export const setLSI18N = (value) => {
  setLocalStorage(LSname.i18n, value, true);
};

export const setLSBEVersion = (value) => {
  setLocalStorage(LSname.be_version, value);
};

// REMOVE
export const removeLSStockAdjust = () => {
  localStorage.removeItem(LSname.stockAdjust);
};

export const removeLSStockAudit = () => {
  localStorage.removeItem(LSname.stockAudit);
};

// CLEAR
export const clearLocalStorage = () => {
  localStorage.clear();
};
