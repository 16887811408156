import { SSName } from '.';

// SETTER
export const setSessionStorage = (key, value, raw) => {
  if (raw) {
    sessionStorage.setItem(key, value);
  } else {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
};

export const setAuthSession = (value) => {
  setSessionStorage(SSName.AUTH, value);
};

export const setCredentialSession = (value) => {
  setSessionStorage(SSName.CRED, value);
};

export const setSessionOutlet = (value) => {
  setSessionStorage(SSName.SELECTEDOUTLET, value);
};

export const setSessionBusiness = (value) => {
  setSessionStorage(SSName.SELECTEDBUSINESS, value);
};

export const setSessionMenu = (value) => {
  setSessionStorage(SSName.SELECTEDMENU, value, true);
};

// CLEAR
export const clearSessionStorage = () => {
  sessionStorage.clear();
};

export const clearAuthCredSession = () => {
  sessionStorage.removeItem(SSName.AUTH);
  sessionStorage.removeItem(SSName.CRED);
};
