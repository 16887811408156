import { getLSCredential, getLSI18N } from 'localstorage/getter';
import { clearLocalStorage, setLSI18N } from 'localstorage/setter';
import { clearSessionStorage } from 'sessionstorage/setter';
import * as Mongo from 'mongoservices/setup';

export const logoutBackOffice = async (noRedirect) => {
  const lang = getLSI18N();
  clearSessionStorage();
  clearLocalStorage();
  setLSI18N(lang);
  await Mongo.LogoutRealm();
  // harusnya bisa pakai navigate (lebih cepet)
  // tapi event listener untuk postMessage tidak bisa di clear
  // paling aman pakai window open, seolah olah baru buka halaman BO
  // semua event handler iframe dll bakal fresh dari awal
  if (!noRedirect) {
    window.open('/login', '_self');
  }
};

export const getBackOfficeUrl = () => {
  const getCredentials = getLSCredential() || null;
  let BOURL = getCredentials?.server_url;
  if (import.meta.env.MODE.toLowerCase().includes('local')) {
    BOURL = 'http://localhost:5000';
  }

  return BOURL;
};
