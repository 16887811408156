/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import * as Realm from 'realm-web';
import { logPayloadResponse } from 'utils/logging';
import { SentryLevel, sentSentryError } from 'utils/sentry';
import { getLSCredential, getLSI18N } from 'localstorage/getter';
import errorHandler from 'utils/errorHandler';

const lang = getLSI18N();

const REALM_APP_ID = `${
  import.meta.env[`VITE_APP_ID_${import.meta.env.MODE.toUpperCase()}`]
}`;
const app = new Realm.App({ id: REALM_APP_ID, timeout: 10000 });

export const collections = {
  user: 'user',
};

export const realmFunction = {
  clientUser: 'clientUser',
  clientOutlets: 'clientOutlets',
  clientProductGroup: 'clientProductGroup',
  clientProductDepartment: 'clientProductDepartment',
  clientUserBusiness: 'clientUserBusiness',
  clientPriceLevel: 'clientPriceLevel',
  clientProductModifier: 'clientProductModifier',
  clientChangePassword: 'clientChangePassword',
  clientProduct: 'clientProduct',
  clientProductPackage: 'clientProductPackage',
  clientUserRole: 'clientUserRole',
  clientProductVariant: 'clientProductVariant',
  clientProductMenu: 'clientProductMenu',
  clientUserAcl: 'clientUserAcl',
  clientPosId: 'clientPosId',
  clientProductStock: 'clientProductStock',
  clientStockAudit: 'clientStockAudit',
  clientRefundReasons: 'clientRefundReasons',
  clientVoidReason: 'clientVoidReason',
  clientTypeSales: 'clientTypeSales',
  clientTaxes: 'clientTaxes',
  clientPaymentMedia: 'clientPaymentMedia',
  clientUserLicenseDevice: 'clientUserLicenseDevice',
  clientUserDevice: 'clientUserDevice',
  clientMember: 'clientMember',
  clientRoom: 'clientRoom',
  clientTableLayout: 'clientTableLayout',
  clientInvoice: 'clientInvoice',
  clientBillDesign: 'clientBillDesign',
  clientSubscription: 'clientSubscription',
  clientBillingHistory: 'clientBillingHistory',
  clientBusinessPlan: 'clientBusinessPlan',
  clientGeneralSetting: 'clientGeneralSetting',
  clientDiscount: 'clientDiscount',
  clientPromo: 'clientPromo',
  clientGenJWTReport: 'clientGenJWTReport',
  clientReportDefaultFilter: 'clientReportDefaultFilter',
  clientEwallet: 'clientEwallet',
  clientBankAccount: 'clientBankAccount',
  clientDisbursement: 'clientDisbursement',
};

export const loginRealm = async () => {
  const jwt_access = getLSCredential();
  const credentials = Realm.Credentials.jwt(jwt_access?.hit_access);
  try {
    // Authenticate the user
    const user = await app.logIn(credentials);

    // if custom data is not exist
    // if (Object.keys(user.customData).length === 0) {
    // await app.currentUser.callFunction(
    //   'clientUpdateRealmId',
    //   jwt_access.hit_access,
    // );
    // await user.logOut();
    // user = await app.logIn(credentials);
    // }

    return user;
  } catch (err) {
    sentSentryError(err, SentryLevel.Fatal);
    console.error('Failed to log in', err);
  }
};

export const Call = async (
  functionName,
  method = 'GET',
  data = {},
  filter = {},
) => {
  try {
    const payload = {
      method,
      headers: {
        Lang: lang || 'id',
      },
      data,
      filter,
    };

    let realmUser = app?.currentUser;
    if (!realmUser) {
      realmUser = await loginRealm();
    }

    const hitServer = await realmUser?.callFunction(functionName, payload);

    logPayloadResponse(functionName, payload, hitServer, 'success');

    return hitServer;
  } catch (err) {
    if (!errorHandler(err)) {
      logPayloadResponse(
        functionName,
        {
          method,
          headers: {
            Lang: lang || 'id',
          },
          data,
          filter,
        },
        null,
        'error',
      );
      sentSentryError(err, SentryLevel.Error);
    }
    return { error: true, message: err };
  }
};

export const LogoutRealm = async () => {
  if (app.currentUser) await app.currentUser.logOut();
};
