import { getLSI18N } from 'localstorage/getter';
import { clearLocalStorage, setLSI18N } from 'localstorage/setter';
import { clearSessionStorage } from 'sessionstorage/setter';
import * as Mongo from 'mongoservices/setup';

const logoutBO = async () => {
  const lang = getLSI18N();
  clearSessionStorage();
  clearLocalStorage();
  setLSI18N(lang);
  await Mongo.LogoutRealm();
  window.location.replace('/login');
};

const isUndefined = (param, expectedType) => {
  if (expectedType !== undefined) {
    if (typeof param !== expectedType) {
      return true;
    }
  } else if (param === undefined) {
    return true;
  }
};

const knownCases = (parameters) => {
  const invalidSession = parameters.errorCode === 'InvalidSession';
  const rejectedByMongo =
    parameters?.message?.url ===
      'https://ap-southeast-1.aws.stitch.mongodb.com/api/client/v2.0/auth/session' &&
    parameters?.message?.statusCode === 401;

  // HANDLE LOGIN REALM RETURN UNEXPECTED
  const loginUndefined =
    isUndefined(parameters.app, 'string') ||
    isUndefined(parameters.server_url, 'string') ||
    isUndefined(parameters.report_app, 'string');

  return invalidSession || rejectedByMongo || loginUndefined;
};

export default (parameters, dontLogout) => {
  let result = false;
  if (knownCases(parameters)) {
    result = true;
    if (!dontLogout) {
      logoutBO();
    }
  }
  return result;
};
