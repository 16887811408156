import { getBackOfficeUrl } from './helper';

const reportPath = import.meta.env.VITE_REPORT_UI;

export const postMessageKeys = {
  sendPMCredential: 'bo_login',
  loginReport: 'LoginReport',
  lang: 'lang',
  requestCredentials: 'requestCredentials',
};

export const listenerPostMessage = (callback) => {
  window.addEventListener('message', (event) => {
    if (
      event.origin !== getBackOfficeUrl() ||
      event?.data?.type?.includes('webpack')
    ) {
      // jika origin tidak sesuai atau unexpected PM webpack
      // return null ke listener
      callback(null);
    } else if (JSON.parse(event?.data)?.data?.iframe_received) {
      // jika iframe_received (untuk console.log development)
      // console.log iframe received, dan return null ke listener
      // gak bisa di satukan dengan diatas karena beda logic
      console.log(JSON.parse(event?.data), '<< berhasil mengirim ke iframe');
      callback(null);
    } else {
      // listen PM normal, jika data JSON, maka parse, jika string, masuk catch
      let result = null;
      try {
        result = JSON.parse(event.data);
      } catch (_) {
        result = event.data;
      }
      callback(result);
    }
  });
};

const senderPostMessage = (targetRef, key, data, isOnLoad, toReport) => {
  if (targetRef) {
    const payload = {
      key,
      data,
    };

    // isOnLoad digunakan untuk postMessage ke iframe saat useEffect
    // karena ketika postmessage sewaktu useEffect, iframe belum ready
    if (isOnLoad) {
      targetRef.addEventListener('load', () => {
        targetRef.contentWindow.postMessage(
          JSON.stringify(payload),
          toReport ? reportPath : getBackOfficeUrl(),
        );
      });
    } else {
      targetRef.contentWindow.postMessage(
        JSON.stringify(payload),
        toReport ? reportPath : getBackOfficeUrl(),
      );
    }
  }
};

// BO
export const senderPostMessageBO = (targetRef, key, data, isOnLoad) => {
  senderPostMessage(targetRef, key, data, isOnLoad, false);
};

// BO Report
export const senderPostMessageReport = (targetRef, key, data, isOnLoad) => {
  senderPostMessage(targetRef, key, data, isOnLoad, true);
};
