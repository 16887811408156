import { getAppId } from '../appIds';
import { SentryLevel, sentSentryError } from 'utils/sentry';

export const realmWebhook = {
  verifyEmail: 'registerVerifyEmail',
  login: 'loginBO',
  businessCategory: 'clientBusinessCategory',
  sendEmailForgotPassword: 'forgotPasswordSendEmail',
  countries: 'clientGetCountry',
  clientGetProvince: 'clientGetProvince',
  clientGetCity: 'clientGetCity',
  clientGetRegion: 'clientGetRegion',
  resetPassword: 'setResetPassword',
  ewalletVerifyEmail: 'ewalletVerifyEmail',
};

export const callWebhook = async (endpoint, body, method, headers) => {
  const localBody = body || {};
  const localMethod = method || 'POST';
  try {
    const getResponse = await fetch(
      `${import.meta.env.VITE_WEBHOOK_URL.replace(
        '[APPID]',
        getAppId(),
      )}/${endpoint}`,
      {
        method: localMethod,
        headers: headers || {
          'Content-Type': 'application/json',
        },
        body:
          localMethod === 'POST' ||
          localMethod === 'PUT' ||
          localMethod === 'DELETE'
            ? JSON.stringify(localBody)
            : null,
      },
    );
    const res = await getResponse.json();
    return res;
  } catch (e) {
    // handle network error
    sentSentryError(e, SentryLevel.Error);
    return { status: false, message: e.toString() };
  }
};
