/* eslint-disable react-refresh/only-export-components */
import { Suspense, lazy } from 'react';
import { Navigate, Routes as ReactRoutes, Route } from 'react-router-dom';
import ReSimpleCircularProgress from 'components/ReSimpleCircularProgress';
import AuthProvider from 'context/AuthContext';
import { DrawerMenuData } from 'assets/file/DrawerMenuList';
import { _ISNOTPRODUCTION } from 'utils/functions';
import { PERMIT_HELPER } from 'assets/file/AccessControl';
import PropTypes from 'prop-types';

const EmailVerification = lazy(() =>
  import('./pages/general-site/EmailVerificationPage'),
);

const EmailEwalletVerificationPage = lazy(() =>
  import('./pages/general-site/EmailEwalletVerificationPage'),
);

const GeneralContainer = lazy(() =>
  import('./pages/general-site/GeneralContainer'),
);

const ResetPassword = lazy(() =>
  import('./pages/general-site/ResetPasswordPage'),
);
const BackOfficeIframe = lazy(() =>
  import(`./pages/back-office/BackOfficeIframe`),
);

const ForgotPassword = lazy(() =>
  import('./pages/back-office/prelogin/forgot-password/ForgotPasswordPage'),
);
const LoginPage = lazy(() =>
  import('./pages/back-office/prelogin/Login/LoginPage'),
);

const PostMessageDev = lazy(() =>
  import('./pages/devpages/PostMessageSendListen'),
);
const DeployInfo = lazy(() => import('./pages/devpages/DeployInfo'));

const DevPath = [
  {
    path: '/dev/postmessage',
    element: <PostMessageDev />,
    auth: true,
    options: {
      title: null,
      menuKey: 'postmessage',
      subMenu: 'postmessage',
    },
    exact: true,
  },
  {
    path: '/dev/info',
    element: <DeployInfo />,
    auth: false,
    options: {
      title: null,
      menuKey: 'deployinfo',
      subMenu: 'deployinfo',
    },
    exact: true,
  },
];

const RoutePaths = [
  { path: '/', element: <Navigate to="/login" replace />, exact: true },
  { path: '/email-verification', element: <EmailVerification />, exact: true },
  {
    path: '/ewallet-email-verify',
    element: <EmailEwalletVerificationPage />,
    exact: true,
  },
  { path: '/reset-password', element: <ResetPassword />, exact: true },
  { path: '/forgot-password', element: <ForgotPassword />, exact: true },
  { path: '/login', element: <LoginPage />, exact: true },
  // diakses oleh child iframe untuk komunikasi
  { path: '/container', element: <GeneralContainer />, exact: true },

  {
    path: '*',
    element: <BackOfficeIframe />,
  },
];

const Element = ({ route }) => {
  const pageConstructor = route.menu
    ? {
        title: route.subMenu
          ? DrawerMenuData[route.menu].subMenu[route.subMenu].key
          : DrawerMenuData[route.menu].key,
        menuKey: DrawerMenuData[route.menu].key,
        subMenu: route.subMenu
          ? DrawerMenuData[route.menu].subMenu[route.subMenu].key
          : '',
        ...route.options,
        permitAccess: PERMIT_HELPER(
          DrawerMenuData[route.menu],
          DrawerMenuData[route.menu].subMenu[route.subMenu],
        ),
        disabled: DrawerMenuData[route.menu].disabled,
      }
    : { permitAccess: true, ...route.options };

  return route.auth ? (
    <AuthProvider {...pageConstructor}>{route.element}</AuthProvider>
  ) : (
    route.element
  );
};

Element.propTypes = {
  route: PropTypes.object,
};

const Page = ({ route }) => {
  return <Element route={route} />;
};

Page.propTypes = {
  route: PropTypes.object,
};

const Routes = () => {
  return (
    <>
      <Suspense fallback={<ReSimpleCircularProgress />}>
        <ReactRoutes>
          {RoutePaths.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<Page route={route} />}
            />
          ))}

          {_ISNOTPRODUCTION() &&
            DevPath.map((devpath) => (
              <Route
                key={devpath.path}
                path={devpath.path}
                element={
                  devpath.auth ? (
                    <AuthProvider {...devpath.options}>
                      {devpath.element}
                    </AuthProvider>
                  ) : (
                    devpath.element
                  )
                }
              />
            ))}
        </ReactRoutes>
      </Suspense>
    </>
  );
};

export default Routes;
