import { getLSPermission } from 'localstorage/getter';

export const ACCESS_BO = {
  REPORT: 'bo_report',
  PRODUCT: 'bo_product',
  UTILITY: 'bo_utility',
  EWALLET: 'bo_ewallet',
  PROMO: 'bo_promo',
  OUTLET: 'bo_outlet',
  TABLE: 'bo_table',
  STOCK_ADJUSTMENT: 'bo_stock_adjustment',
  STOCK_AUDIT: 'bo_stock_audit',
  MEMBER: 'bo_member',
  INVOICE: 'bo_invoice',
  STAFF: 'bo_staff',
  ACCOUNT_SETTING: 'bo_account_settings',
};

export const ISPERMITTED = async (key, permitData) => {
  let isPermit = false;
  const permission = permitData || getLSPermission();

  if (typeof key === 'string' && permission && Array.isArray(permission)) {
    const permit = permission.find((p) => p.id === key);
    isPermit = permit.value;
  } else if (typeof key === 'boolean') {
    isPermit = key;
  }
  return isPermit;
};

const CHECK_PERMIT = (aclRoute, subMenu) => {
  const checkPermitArray = (arrayAcl) => {
    let isPermit = false;
    arrayAcl.permitAccess.forEach((acl) => {
      isPermit = isPermit || ISPERMITTED(acl);
    });

    return isPermit;
  };

  // JIKA ACLROUTE ARRAY MAKA ACL DEPEND KE ARRAY TERSEBUT (CEK SATU SATU PERMIT ARRAY NYA)
  // INI UNTUK HANDLE DRAWER YANG TIPENYA ARRAY
  if (Array.isArray(aclRoute.permitAccess)) {
    return checkPermitArray(aclRoute);
  }
  // JIKA ACLROUTE UNDEFINED
  // MAKA TIDAK PERLU MELIHAT PARENT NYA, CEK SUBMENUNYA
  else if (typeof aclRoute.permitAccess === 'undefined') {
    if (Array.isArray(subMenu.permitAccess)) {
      return checkPermitArray(subMenu);
    } else {
      return ISPERMITTED(subMenu.permitAccess);
    }
  }
  return ISPERMITTED(aclRoute.permitAccess);
};

const CHILD_PERMIT = (subMenu) => {
  let isPermit = false;
  subMenu.forEach((acl) => {
    if (!Array.isArray(acl.permitAccess)) {
      isPermit = isPermit || ISPERMITTED(acl.permitAccess);
    }
  });
  return isPermit;
};

export const PERMIT_HELPER = (aclRoute, subMenu) => {
  // MACAM MACAM TYPEOF ACL
  // bool dan string digunakan untuk permanentDrawer (MENU & SUB MENU) bool digunakan untuk force jadi true, seperti halaman profile
  // object digunakan untuk file Route, karena passingnya bentuk object biar bisa di olah
  // undefined digunakan untuk parent yang accessnya tergantung dari child nya
  switch (typeof aclRoute) {
    case 'boolean':
    case 'string':
      // UNTUK MENU DRAWER SEBELAH KIRI
      return ISPERMITTED(aclRoute);
    case 'object':
      // UNTUK FILE ROUTES DAN MANAGEMENT UI AUTHCONTEXT (DAN JUGA UNTUK DRAWER YANG TYPENYA ARRAY)
      return CHECK_PERMIT(aclRoute, subMenu);
    case 'undefined':
      // UNTUK MENU DRAWER SEBELAH KIRI YANG DEPEND DARI CHILD
      return CHILD_PERMIT(subMenu);

    default:
      return ISPERMITTED(aclRoute);
  }
};
